import "./MuverTrip.css"

import * as Actions from '../../../redux/actions'

import React from 'react'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect, useDispatch } from 'react-redux'
import { member_area_router } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"
import { Button } from "react-bootstrap"
import { getBookingFromText } from "./BookingUpload.services"
import { CircularProgress } from "@material-ui/core"
import { saveHotel, saveHotelTimeline } from "../BookingPage/PaymentPage/payment.thunk"


/* top bar component */
const BookingUpload_ = ({ uuid, setOpenTimeline, getPublicTrip, logo_width, switch_container, activeTrip, uploadTripImage, getSelectedTripInformation, trip_public, trip_private, lang, screenSize, from, uuidPublic, uuidPrivate, tripImage }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [postContent, setPostContent] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);



    const getBooking = async () => {
        setSubmitting(true)
        const result  = await getBookingFromText({
            uuid: uuid,
            userContent: postContent
        })
        if(result){
            await addToTimeline(result)
        }
   
        setSubmitting(false)
    }



    // {
    //     "confirmation_number": "72572008873065",
    //     "reference_number": "Itinerary # 72572008873065",
    //     "hotel_address": "194 Jardin de l Arche, Nanterre, Hauts-de-Seine, 92000 France",
    //     "hotel_city": "Nanterre",
    //     "hotel_country": "France",
    //     "hotel_latitude": null,
    //     "hotel_longitude": null,
    //     "hotel_name": "citizenM Paris la Défense",
    //     "check_in": "Sat, Jun 3, 2:00 PM",
    //     "check_out": "Mon, Jun 5, 11 AM",
    //     "number_of_guests": 2,
    //     "number_of_rooms": 1,
    //     "guests": [
    //         {
    //             "first_name": "Michel",
    //             "last_name": "Karam"
    //         }
    //     ],
    //     "rooms": [
    //         {
    //             "room_type": "King Room",
    //             "room_description": "View special requests in your itinerary. All special requests (such as in-room amenities, bed type, and smoking preference) are shared with the hotel, but requests are not guaranteed and may incur additional charges. We recommend you confirm them directly with the hotel before travel."
    //         }
    //     ]
    // }


    const addToTimeline = async ( booking) => {

        if (booking) {
            const saveData = {
                postData: {
                    tripId: activeTrip?.tripId || "",
                    pricelineHotelId: booking?.hotel_id || "",
                    booking_trip_number: booking?.confirmation_number || "",
                    booking_status: "BOOKED",
                    bookedEmail:booking?.email||"",
                    booking_status_code: booking?.reference_number || "",
                    checkIn: booking.check_in || "",
                    checkOut: booking.check_out || "",
                    displaySymbol: booking?.rate_symbol || "$",
                    adults: booking?.number_of_guests || 1,
                    displayCurrency: booking?.rate_currency || "US",
                    displayTotalFare: booking?.rate_total || ""
                },
                uuid: uuid || null
            }
      

           const result = await dispatch(saveHotel(saveData));
           if(result?.payload){
            setPostContent("")
           }
     
   
        }

    }

    return (
        <div className="" style={{
            background: "lightgrey",
            padding: 30
        }}  >
            <div style={{
                font: "18px/16px Futura Md BT",
            }}>
                Copy and paste email confirmation text here
            </div>

            <textarea className="uploadBookingArea" style={{
                background: "white",
                border: "none",
                resize: "none",
                padding: 40,
                width: "100%",
                marginTop: 20,
                borderRadius: 20,
                font: "16px/16px Futura Lt BT",
            }} rows="10" onChange={e => setPostContent(e.target.value)}
                placeholder="Copy and Paste Here"
                value={postContent}>

            </textarea>

            <div style={{ width: "100%", textAlign: "right" }}>

                <Button onClick={getBooking} style={{ font: "16px/16px Futura Md BT", borderRadius: 15, marginTop: 15, width: "150px", height: "40px", color: "white", backgroundColor: "var(--mainBlue)" }}>
                    {submitting ? <CircularProgress size={14} color="inherit" /> : <span style={{ font: "16px/16px Futura Md BT" }}>SUBMIT</span>}</Button>

            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        trip_public: state.PublicTrip.trip,
        trip_private: state.Member.memberArea.selectedTrip,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        uuid: state.Member.authModal.uuid,
        tripId: state.PublicTrip.tripId,
        lang: state.Setting.lang,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        uuidPublic: state.Social.member_uuid,
        uuidPrivate: state.Member.authModal.uuid,
        logo_width: state.Setting.appMenu.logo.width,

        tripImage: state.Member.memberArea.tripImage,
        switch_container: state.Member.switch_container,
    }
}

const mapDispatchToProps = {
    getPublicTrip: Actions.getPublicTrip,
    getSelectedTripInformation: Actions.getSelectedTripInformation,
    setTripToTimeline: Actions.setTripToTimeline,
    uploadTripImage: Actions.uploadTripImage,
}
export const BookingUpload = connect(mapStateToProps, mapDispatchToProps)(BookingUpload_)
