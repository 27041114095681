import {
    DELAY_ONE_SECOND,
    IMAGE_CLOUD_MAX,
} from "../Common/RegExValidate"
import { image_boxing, loadingImage } from '../Common/RegExValidate'
import "./DestinationPage.css"
import { Image } from "react-bootstrap"
import { PhotoVideoIcon } from '../Common/SVGBank'
import React from 'react'
import { SRLWrapper } from "simple-react-lightbox"
import SimpleReactLightbox from "simple-react-lightbox" // Import Simple React Lightbox
import { connect, useDispatch } from 'react-redux'
import AddPhotoComponent from "../Common/AddPhotoComponent"
import { fetchDestinationPhotoGallery } from "../../../redux/actions"

// import { CameraRegulerIcon } from '../Common/SVGBank'







const TopBarSlider_ = ({ timelineStatus,sliderImages,lang,generalInfo, screenSize, isMobile, switchFeatureContainer, destinationId }) => {
    const [imageRef, setImageRef] = React.useState(null)
    const browser = navigator.userAgent
    const isFirefox = browser.indexOf('Firefox') > -1
    const [open, setOpen] = React.useState(false)

    const dispatch = useDispatch()
    React.useEffect(() => {
        setOpen(false)
        setTimeout(
            () => { setOpen(true) }
            , DELAY_ONE_SECOND)
    }, [destinationId])




    const destinationName = generalInfo !== undefined && generalInfo !== null ? generalInfo.name : ""
    let topImageHeight = isMobile ? "180" : "650"
    let containerH = 500
    let fontDestinationName = isMobile ? "25" : "50"
    let fontIcon = isMobile ? "10" : "16"
    let widthIcon = isMobile ? "110px" : "150px"
    let heightIcon = isMobile ? "30px" : "45px"
    let fontHeightIcon = isMobile ? "17px" : "30px"
    // let fontIconCameraReguler = isMobile ? "15" : "20"
    if (topImageHeight !== undefined) {
        containerH = topImageHeight + "px"
    }
    let padding_l  = isMobile ? "15.5%" : (timelineStatus ? "calc(340px + 13%)" : "15.5%")
 


    
    let sliderIndex = 0
    let destinationImage = sliderImages !== undefined && sliderImages.length > 0 && sliderImages[sliderIndex] !== undefined ? image_boxing(sliderImages[sliderIndex], screenSize, topImageHeight) : loadingImage.src + "?func=crop&w=" + Math.floor(screenSize) + "&h=" + Math.floor(topImageHeight)
    const isGray = destinationImage && !(destinationImage?.includes("gray")) ? "650" : "250";
    topImageHeight = isMobile ? "180" : isGray


    const display_letters = screenSize > 1024 ? 29 : 20
    const options = open && sliderImages !== undefined && sliderImages.length > 0 ? {
        settings: {
            hideControlsAfter: null,

            autoplaySpeed: 1500,
            transitionSpeed: 900,
            overlayColor: '#000000',
            usingPreact: false
        },
        caption: {
            captionColor: "rgba(25, 188, 155, 1)",
            captionFontFamily: "Futura Md BT",
            captionFontSize: "22px",
            captionFontWeight: "300",
            captionFontStyle: "capitalize",
        },
        buttons: {
            showFullscreenButton: false,
            showCloseButton: true,
            buttonsBackgroundColor: "#191919",
            buttonsIconColor: "rgba(25, 188, 155, 1)",
        },
    } : {}
    let fontIconPhotoVideo = isMobile ? "18" : "25"




    const getGalleryImages = ()=>{
        imageRef.click()
        dispatch(fetchDestinationPhotoGallery(destinationId, lang,10))
    }


    return (
        <div className="position-relative z_index_2 topBarDestination">
            <img
                src={destinationImage}
                alt="destinationimage" width={screenSize} height={topImageHeight} />
            {(sliderImages === undefined || sliderImages.length <= 0 || sliderImages[sliderIndex] === undefined) && <div className="mask_block" style={{ height: containerH }}></div>}



            <div className="z_index_3"
                style={{
                    top: "30%",
                    left: "0",
                    right: "0",
                    position: "absolute",
                }}>
                <div className="box_1 text-left"
                    style={{
                        font: fontDestinationName + "px/80px Futura Md BT",
                        color: "#FFFFFF",
                        paddingLeft: padding_l
                    }} >{destinationName !== undefined && destinationName.length > display_letters + 1 ? destinationName.substr(0, display_letters) + "..." : destinationName}</div>
            </div>
            {open && sliderImages !== undefined && sliderImages.length > 0 && sliderImages[0] !== undefined
                && sliderImages[0].imageName !== undefined &&
                <SimpleReactLightbox
                ><div className=""
                    style={{
                        bottom: isMobile? 30:100,
                        marginRight:isMobile?30:100,
                        position: "relative",
                    }}>
                        <div className="box_1 text-right"
                            style={{
                                color: "#FFFFFF",
                                paddingLeft: "8.5%",
                                height:"100%"
                          
                            }} >
                            <div className="d-inline-block"
                                onClick={getGalleryImages}
                                style={{
                                    width: widthIcon,
                                    height: heightIcon,
                                    background: "#FFFFFF",
                                    color: switchFeatureContainer.color,
                                    border: "1px solid " + switchFeatureContainer.color,
                                    borderRadius: "39px",
                                    textAlign: "center",
                                    paddingTop: '5px',
                                    font: fontIcon + "px/" + fontHeightIcon + " Futura Md BT",
                                    marginRight: "8px",
                                    cursor: "pointer",
                                }}>
                                {open && sliderImages !== undefined && sliderImages.length > 0 && <SRLWrapper options={options} >
                                    <PhotoVideoIcon width={fontIconPhotoVideo} fill={switchFeatureContainer.color} />{" View Gallery"}

                                    {sliderImages.map((image, index) => {
                                        return (
                                            index === 0 ?
                                                <React.Fragment>
                                                    <Image
                                                        key={index}
                                                        style={{
                                                            position: "absolute",
                                                            right: "200%",
                                                        }}
                                                        src={image_boxing(image, screenSize * 0.9, screenSize * 0.4)}
                                                        className="img_fit_cover d-block w-100"
                                                        ref={imageClick => setImageRef(imageClick)}
                                                        alt={image.userHandle? "@"+image.userHandle :image.description} />

                                                   
                                                </React.Fragment>
                                                : (index < IMAGE_CLOUD_MAX &&
                                                    <React.Fragment>
                                                    <Image
                                                        key={index}
                                                        style={{
                                                            position: "absolute",
                                                            right: "200%"
                                                        }}
                                                        src={image_boxing(image, screenSize * 0.9, screenSize * 0.4)}
                                                        className="img_fit_cover d-block w-100"
                                                        alt={image.userHandle? "@"+image.userHandle :image.description} />
                                                         </React.Fragment> 
                                                        )
                                        )
                                    })}
                                </SRLWrapper>}
                            </div>
                            <AddPhotoComponent dataId={destinationId} dataKey={"destinationId"} />
                        </div>
                    </div>
                </SimpleReactLightbox>
            }

        </div >
    )

}


const mapStateToProps = state => {
    return {
        screenSize: Math.min(state.Setting.htmlBody.bodyClientWidth, document.body.clientWidth),
        isMobile: state.Setting.is_mobile,
        sliderImages: state.Destination.destination.sliderImages,
        generalInfo: state.Destination.destination.generalInfo,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        lang: state.Setting.lang,
        destinationId: state.Destination.destination.activeId,
        timelineStatus: state.FormBank.TimelineWizard.status,
    }
}

const mapDispatchToProps = {
}

const DestinationTopBarSlider = connect(mapStateToProps, mapDispatchToProps)(TopBarSlider_)
export default DestinationTopBarSlider
